<template>
  <div class="affiliates-conten-con">
    <!-- <h2 class="h2-faq">자주 묻는 질문 TOP 20</h2> -->
    <div class="search-box faq-search-box">
      <input
        v-model="searchText"
        type="text"
        class="text"
        placeholder="업체명을 입력하세요."
      />
      <button class="btn __search" @click="search">검색</button>
    </div>
    <div class="affiliatesTitle">협력기관</div>
    <ul class="qna-list">
      <li
        class="affiliates-list"
        v-for="(Board, idx) in instDesc"
        :key="`Board_${idx}`"
      >
        <div class="affiliates-con">
          <div class="affiliatesLogo-con">
            <div v-html="Board.bordCont"></div>
          </div>
          <div class="affiliatesName-con">
            <p>{{ `${Board.bordNm.split('/')[0]}` }}</p>
            <p>{{ `${Board.bordNm.split('/')[1]}` }}</p>
          </div>
        </div>
      </li>
      <li class="length_0" v-if="Boards.length == 0">
        <div class="q">
          <p
            class="q2"
            style="margin: auto; text-align: center; color: #090909"
          >
            제휴사가 존재하지 않습니다.
          </p>
        </div>
      </li>
    </ul>
    <!-- ------------------------------------------------------------------ -->
    <div class="affiliatesTitle">협력회사</div>
    <ul class="qna-list">
      <li
        class="affiliates-list"
        v-for="(Board, idx) in compDesc"
        :key="`Board_${idx}`"
      >
        <div class="affiliates-con">
          <div class="affiliatesLogo-con">
            <div v-html="Board.bordCont"></div>
          </div>
          <div class="affiliatesName-con">
            <p>{{ `${Board.bordNm.split('/')[0]}` }}</p>
            <p>{{ `${Board.bordNm.split('/')[1]}` }}</p>
          </div>
        </div>
      </li>
      <li class="length_0" v-if="Boards.length == 0">
        <div class="q">
          <p
            class="q2"
            style="margin: auto; text-align: center; color: #090909"
          >
            제휴사가 존재하지 않습니다.
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getBoardsList } from 'Api/modules'
import _ from 'lodash'
export default {
  components: {},
  data() {
    return {
      bordNum: null,
      searchText: '',
      Boards: [],

      // paging
      pageNumber: 0,
      totalElements: 0,
      pageSize: 100,
      pageLinkCount: 5,
      currentPageIndex: 1,
      propCd: 3,
      bordNm: '',
      no: '',
      piece1: '',
      piece2: '',
      piece3: '',
      logoUrl: '',
    }
  },
  computed: {
    partnerInst: function () {
      return _.filter(
        this.Boards,
        partnerCompList => partnerCompList.notcWght === 'H',
      )
    },
    instDesc: function () {
      return _.sortBy(
        this.partnerInst,
        partnerDescList => partnerDescList.bordNm.split('/')[0],
      )
    },
    partnerComp: function () {
      return _.filter(
        this.Boards,
        partnerCompList => partnerCompList.notcWght === 'L',
      )
    },
    compDesc: function () {
      return _.sortBy(
        this.partnerComp,
        partnerDescList => partnerDescList.bordNm.split('/')[0],
      )
    },
  },
  watch: {},
  methods: {
    async search() {
      const res = await getBoardsList({
        schKeyword: this.searchText,
        pageNumber: this.pageNumber,
        propCd: this.propCd,
        bordNo: this.bordNo,
        bordNm: this.bordNm,
        pageSize: this.pageSize,
      })
      this.Boards = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber
      this.pageSize = res.pageable.pageSize
      this.no =
        this.totalElements - (this.currentPageIndex - 1) * this.listRowCount
    },
    // affiliatesUrl(logoNo) {
    //   this.piece1 = logoNo.search('<img')
    //   this.piece2 = logoNo.indexOf('h', this.piece1)
    //   this.piece3 = logoNo.indexOf('>', this.piece1 + 5)
    //   this.logoUrl = logoNo.substring(this.piece1, this.piece3)
    //   return this.logoUrl
    // },
  },
  created() {
    this.$nextTick(() => {
      this.search()
      // const list = document.querySelector('.affiliates-list')
      // const BASE_DISPLAY = "block";
      // const OTHER_BASE_DISPLAY = 'none'
      // const currentDisplay = list.style.display
      // if (currentDisplay === OTHER_BASE_DISPLAY) {
      //   list.remove()
      // }
    })
  },
}
</script>

<style></style>
